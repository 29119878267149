<template>
  <div class="form">
    <van-nav-bar title="我的居民健康码"  left-text='首页' left-arrow @click-left="onClickLeft" />
    <van-notice-bar
      background="#8ED8FF"
      color="#0038CF"
      left-icon="volume-o"
      text="为了您和他人的健康，请如实填写，所填写信息会受到严格保护"
    />

    <div class="form-div">
      <div class="input-item" v-for="(item, index) in inputList" :key="index">
        <div class="form-tit">
          <span v-if="item.required">*</span>{{ item.title }}
        </div>
        <div class="input-div" v-if="item.type == 'text'">
          <input :placeholder="item.placeholder" v-model="item.value" />
        </div>
        <div style="margin: 10px 0 0" v-if="item.type == 'radio'">
          <van-radio-group
            class="radio-group"
            v-model="item.value"
            direction="horizontal"
          >
            <van-radio
              class="input-div input-div-radio"
              :name="item2.value"
              v-for="(item2, index2) in item.radio"
              :key="index2"
              >{{ item2.name }}</van-radio
            >
          </van-radio-group>
        </div>
        <div
          class="input-div picker1"
          style="margin: 10px 0 10px 0"
          v-if="
            item.type == 'picker1' ||
            item.type == 'picker2' ||
            item.type == 'picker3'
          "
        >
          <input
            readonly
            :placeholder="item.placeholder1"
            v-model="item.value1"
            @click="toPicker(item.key)"
          />
          <van-icon name="arrow-down" color="#0038CF" class="icon" />
        </div>
        <div
          class="input-div picker1"
          style="margin: 0 0 20px 0"
          v-if="item.type == 'picker3'"
        >
          <input
            readonly
            :placeholder="item.placeholder2"
            v-model="item.value2"
            @click="toPicker('building2')"
          />
          <van-icon name="arrow-down" color="#0038CF" class="icon" />
        </div>
        <div
          class="input-div picker1"
          style="margin: 0 0 20px 0"
          v-if="item.type == 'picker1'"
        >
          <input :placeholder="item.placeholder2" v-model="item.value2" />
        </div>
      </div>
      <div class="form-tit">出示证明照片上传</div>
      <div class="uploader">
        <van-uploader :after-read="afterRead" v-model="fileList" multiple>
          <div class="img-bg">
            <img src="@/assets/up.png" />
          </div>
        </van-uploader>
      </div>
      <div class="submit-btn" @click="toSubmit">提交</div>
      <!-- 选择器 -->
      <van-popup v-model="pickCommunity" position="bottom">
        <van-picker
          title="请选择"
          show-toolbar
          :columns="communityList"
          @confirm="confirmCommunity"
          @cancel="pickCommunity = false"
          value-key="name"
          @change="communityChange"
        />
      </van-popup>
      <van-popup v-model="pickGrid" position="bottom">
        <van-picker
          title="请选择"
          show-toolbar
          :columns="gridList"
          @confirm="confirmGrid"
          @cancel="pickGrid = false"
          value-key="name"
          @change="gridChange"
        />
      </van-popup>
      <van-popup v-model="pickGrid2" position="bottom">
        <van-picker
          title="请选择"
          show-toolbar
          :columns="gridList2"
          @confirm="confirmGrid2"
          @cancel="pickGrid2 = false"
          value-key="name"
          @change="gridChange2"
        />
      </van-popup>
      <van-popup v-model="pickBuild" position="bottom">
        <van-picker
          title="请选择"
          show-toolbar
          :columns="buildingList"
          @confirm="confirmBuild"
          @cancel="pickBuild = false"
          value-key="name"
        />
      </van-popup>
      <van-popup v-model="pickAddress" position="bottom">
        <van-area
          title="选择地址"
          value="360321"
          :area-list="areaList"
          @confirm="confirmAddress"
          @cancel="pickAddress = false"
        />
      </van-popup>
      <!-- <van-popup v-model="showPicker3" position="bottom">
        <van-picker
          title="请选择"
          show-toolbar
          :columns="columns3"
          @confirm="onConfirm3"
          @cancel="showPicker3 = false"
          value-key="name"
        />
      </van-popup>
      <van-popup v-model="showPicker4" position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择年月日"
          @confirm="onConfirm4"
          @cancel="showPicker4 = false"
          :min-date="minDate"
          :max-date="maxDate"
        />
      </van-popup>
      <van-popup v-model="showPicker5" position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择年月日"
          @confirm="onConfirm5"
          @cancel="showPicker5 = false"
          :min-date="minDate"
          :max-date="maxDate"
        />
      </van-popup> -->
    </div>
  </div>
</template>
<script>
import { areaList } from "@vant/area-data";
import {
  detail,
  wechatLogin,
  communityAll,
  gridAll,
  buildingAll,
  uploadImage,
  peopleCreate,
  categoryList,
} from "@/api/apiData";
import GetQueryString from "@/until/getParams";

import {
  Icon,
  NavBar,
  Form,
  Field,
  CellGroup,
  Popup,
  Picker,
  RadioGroup,
  Radio,
  DatetimePicker,
  Uploader,
  NoticeBar,
  Area,
  Toast,
  Dialog,
} from "vant";
export default {
  components: {
    [NoticeBar.name]: NoticeBar,
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Form.name]: Form,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [DatetimePicker.name]: DatetimePicker,
    [Uploader.name]: Uploader,
    [Area.name]: Area,
  },
  data() {
    return {
      pickAddress: false, //地址
      pickCommunity: false, //乡镇
      pickGrid: false, //片区
      pickGrid2: false, //片区
      pickBuild: false, //网格点
      community_id: "", //乡镇id
      grid_id: "",
      areaList,
      inputList: [
        {
          title: "户主姓名",
          required: true,
          value: "",
          type: "text",
          key: "householder_name",
          placeholder: "请填写户主姓名",
        },
        {
          title: "成员姓名",
          required: true,
          value: "",
          type: "text",
          key: "name",
          placeholder: "请填写成员姓名",
        },
        {
          title: "性别",
          required: true,
          value: "",
          type: "radio",
          key: "sex",
          radio: [
            {
              name: "男",
              value: "1",
            },
            {
              name: "女",
              value: "2",
            },
          ],
        },
        {
          title: "与户主关系属性",
          required: true,
          value: "",
          type: "radio",
          key: "household_relation",
          radio: [
            {
              name: "本人",
              value: "1",
            },
            {
              name: "夫妻",
              value: "2",
            },
            {
              name: "父母",
              value: "3",
            },
            {
              name: "子女",
              value: "4",
            },
            {
              name: "其他",
              value: "5",
            },
          ],
        },
        {
          title: "年龄",
          required: true,
          value: "",
          type: "text",
          key: "age",
          placeholder: "请填写年龄",
        },
        {
          title: "现居住地具体地址",
          required: true,
          value1: "",
          value2: "",
          value: "",
          type: "picker1",
          key: "address",
          placeholder1: "请选择具体地址",
          placeholder2: "请输入详细地址",
        },
        {
          title: "县内或县外居住",
          required: true,
          value: "",
          type: "radio",
          key: "in_local",
          radio: [
            {
              name: "县内居住",
              value: "1",
            },
            {
              name: "县外居住",
              value: "0",
            },
          ],
        },
        {
          title: "县外居住地名及原因（如在某地务工、学习等，县内填“无”）",
          required: true,
          value: "",
          type: "text",
          key: "outside_live_reason",
          placeholder: "请填写原因",
        },
        {
          title: "租赁或自有房",
          required: true,
          value: "",
          type: "radio",
          key: "house_status",
          radio: [
            {
              name: "租赁",
              value: "2",
            },
            {
              name: "自有房",
              value: "1",
            },
          ],
        },
        {
          title: "联系电话/手机",
          required: true,
          value: "",
          type: "text",
          key: "telephone",
          placeholder: "请填写联系方式",
        },
        {
          title: "已接种/未接种情况",
          required: true,
          value: "",
          type: "radio",
          key: "vaccination_times",
          radio: [
            {
              name: "未接种",
              value: "0",
            },
            {
              name: "已打第一针",
              value: "1",
            },
            {
              name: "已打第二针",
              value: "2",
            },
            {
              name: "已打第三针",
              value: "3",
            },
            {
              name: "有不能接种的禁忌症",
              value: "-1",
            },
          ],
        },
        {
          title: "健康状况",
          required: true,
          value: "",
          type: "radio",
          key: "health_status",
          radio: [
            {
              name: "健康",
              value: "1",
            },
            {
              name: "一般",
              value: "2",
            },
            {
              name: "年老（70岁以上）",
              value: "3",
            },
            {
              name: "行动不便",
              value: "4",
            },
          ],
        },
        {
          title: "所属镇或乡",
          required: true,
          value1: "",
          value: "",
          type: "picker2",
          key: "community",
          placeholder1: "请选择所属镇或乡",
        },
        {
          title: "所属片区/网格点",
          required: true,
          value1: "",
          value2: "",
          value: "",
          type: "picker3",
          key: "building1",
          placeholder1: "请选择片区",
          placeholder2: "请选择网格",
        },
        {
          title: "楼栋/居民点",
          required: true,
          value: "",
          value1: "",
          type: "picker2",
          key: "building_id",
          placeholder1: "请选择楼栋/居民点",
        },
        {
          title: "门牌号",
          required: true,
          value: "",
          type: "text",
          key: "house_number",
          placeholder: "请输入门牌号",
        },
      ],
      result: "",
      result2: "",
      communityList: [
        {
          id: 1,
          name: "社区1",
        },
        {
          id: 2,
          name: "社区2",
        },
      ],
      gridList: [
        {
          id: 1,
          name: "1",
        },
        {
          id: 2,
          name: "2",
        },
      ],
      gridList2: [],
      buildingList: [
        {
          id: 1,
          name: "网格1",
        },
        {
          id: 2,
          name: "网格2",
        },
      ],
      showPicker: false,
      showPicker2: false,
      value1: "",
      checked: "0",
      result3: "",
      showPicker3: false,
      showPicker4: false,
      result4: "",
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(2022, 4, 30),
      showPicker5: false, //*何时来昌
      result5: "", //*何时来昌
      fileList: [],
      images: [],
    };
  },
  mounted() {
    this.getCommunityAll();
    let code = GetQueryString("code");
    var logins = localStorage.getItem("logins");
    if (code) {
      if (logins == 2) {
        this.getInfo();
      } else {
        this.login();
      }
    } else {
      this.getInfo();
    }
    this.getCategoryList();
  },
  methods: {
    // 户主关系属性
    getCategoryList() {
      categoryList({ key: "household_relation" }).then((res) => {
        res.data.forEach((item) => {
          item.value = item.id;
        });
        this.inputList[3].radio = res.data;
      });
    },
    // 登陆
    login() {
      wechatLogin({
        code: GetQueryString("code"),
      }).then((res) => {
        // console.log(res);
        if (res.code == 1) {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("logins", 2);
          this.getInfo();
        } else {
          this.getInfo();
        }
      });
    },
    // 用户详情
    getInfo() {
      detail().then((res) => {
        // this.name = res.data.nickname;
      });
    },
    // 提交
    toSubmit() {
      console.log(this.inputList);
      let obj = {};
      this.inputList.forEach((item) => {
        if (item.key == "address") {
          item.value = item.value1 + item.value2;
        }
        obj[item.key] = item.value;
      });
      obj.images = this.images.toString();
      console.log(obj);
      if (obj.householder_name == "") {
        Toast.fail("请输入户主姓名");
        return false;
      }
      if (obj.name == "") {
        Toast.fail("请输入成员姓名");
        return false;
      }
      if (obj.sex == "") {
        Toast.fail("请选择性别");
        return false;
      }
      if (obj.household_relation == "") {
        Toast.fail("请选择与户主关系属性");
        return false;
      }
      if (obj.age == "") {
        Toast.fail("请填写年龄");
        return false;
      }
      if (obj.address == "") {
        Toast.fail("请填写具体地址");
        return false;
      }
      if (obj.in_local == "") {
        Toast.fail("请选择县内或县外居住");
        return false;
      }
      let that =this
      Dialog.confirm({
        title: "提示",
        message: "确定要提交吗？",
      }).then(() => {
        peopleCreate(obj).then((res) => {
          if (res.code == 1) {
            Toast.success(res.msg);
            that.$router.push('/list')
          } else {
            Toast.fail(res.msg);
          }
        });
      });
    },
    onClickLeft() {
      this.$router.push('/index');
    },
    toPicker(e) {
      console.log(e);
      if (e == "address") {
        this.pickAddress = true;
      }
      if (e == "community") {
        this.pickCommunity = true;
      }
      if (e == "building1") {
        if (this.community_id == "") {
          Toast.fail("请选择所属镇或乡");
          return false;
        }
        this.getGridAll();
        this.pickGrid = true;
      }
      if (e == "building2") {
        if (this.grid_id == "") {
          Toast.fail("请选择片区");
          return false;
        }
        this.getGridAll2();
        this.pickGrid2 = true;
      }
      if (e == "building_id") {
        if (this.grid_id == "") {
          Toast.fail("请选择网格");
          return false;
        }
        this.getBuilding();
        this.pickBuild = true;
      }
    },
    // 获取社区
    getCommunityAll() {
      communityAll().then((res) => {
        this.communityList = res.data;
      });
    },
    // 获取片区
    getGridAll() {
      gridAll({ community_id: this.community_id }).then((res) => {
        this.gridList = res.data;
      });
    },
    // 获取网格
    getGridAll2() {
      gridAll({
        community_id: this.community_id,
        parent_id: this.grid_id,
      }).then((res) => {
        res.data.forEach(item=>{
          item.name = item.name+'-'+item.alias
        })
        this.gridList2 = res.data;
      });
    },
    // 楼栋
    getBuilding() {
      buildingAll({ grid_id: this.grid_id2 }).then((res) => {
        this.buildingList = res.data;
      });
    },
    // 确定地址
    confirmAddress(e) {
      console.log(e);
      let address = e[0].name + e[1].name + e[2].name;
      this.inputList[5].value1 = address;
      this.pickAddress = false;
    },
    // 确定社区
    confirmCommunity(e) {
      console.log(e);
      console.log(this.inputList[5]);
      this.inputList[12].value1 = e.name;
      this.inputList[12].value = e.id;
      this.community_id = e.id;
      this.pickCommunity = false;
    },
    communityChange() {
      this.inputList[13].value1 = "";
      this.inputList[13].value2 = "";
      this.grid_id = "";
      this.grid_id2 = "";
      this.inputList[14].value1 = "";
      this.inputList[14].value = "";
    },
    // 确定片区
    confirmGrid(e) {
      this.inputList[13].value1 = e.name;
      this.grid_id = e.id;
      this.pickGrid = false;
    },
    gridChange() {
      this.inputList[13].value2 = "";
      this.grid_id2 = "";
      this.inputList[14].value1 = '';
      this.inputList[14].value = '';
    },
      gridChange2() {
      this.inputList[14].value1 = '';
      this.inputList[14].value = '';
    },
    // 确定网格
    confirmGrid2(e) {
      this.inputList[13].value2 = e.name;
      this.grid_id2 = e.id;
      this.pickGrid2 = false;
    },
    // 确定楼栋
    confirmBuild(e) {
      this.inputList[14].value1 = e.name;
      this.inputList[14].value = e.id;
      this.pickBuild = false;
    },
    // 上传图片
    afterRead(e) {
      console.log(e);
      console.log(e.file);
      let file = "";
      let img = [];
      if (e.content) {
        file = e.content;
        uploadImage({ file: file }).then((res) => {
          if (res.code == 1) {
            Toast.success("上传成功");
            this.images.push(res.data.path);
          } else {
            Toast.fail(res.msg);
          }
        });
      } else {
        e.forEach((item) => {
          uploadImage({ file: item.content }).then((res) => {
            if (res.code == 1) {
              Toast.success("上传成功");
              this.images.push(res.data.path);
            } else {
              Toast.fail(res.msg);
            }
          });
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.form {
  padding-bottom: 40px;
  overflow-x: hidden;
}
.form-div {
  padding: 10px;
  .form-tit {
    font-size: 14px;
    span {
      color: #ff1111;
    }
  }
  .input-div {
    box-shadow: 1px 1px 5px gainsboro;
    padding: 8px 5px;
    margin: 10px 0 20px;
    input {
      border: none;
      width: 100%;
      padding: 5px 0;
    }
  }
  .radio-group {
    justify-content: space-between;
    align-items: center;
  }
  .input-div-radio {
    width: 45%;
    text-align: center;
    margin: 0 0 10px;
    justify-content: center;
  }
  .picker1 {
    position: relative;
    .icon {
      position: absolute;
      right: 10px;
      top: 13px;
    }
  }
}

.input-tit {
  background: #8ed8ff;
  color: #2e2e2e;
  font-size: 17px;
  padding: 9px 19px;
  font-weight: bold;
}
.uploader {
  width: 100%;
  margin: 10px 0;
  .img-bg {
    padding: 15px;
    background: #f2f2f2;
    border-radius: 5px;
    img {
      width: 65px;
    }
  }
}
.submit-btn {
  background: linear-gradient(to bottom, #5cd9ff, #005ff7);
  color: white;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  line-height: 2.5;
  width: 60%;
  margin: 20px auto 40px;
  border-radius: 40px;
}
</style>